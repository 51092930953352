import React, { useEffect, useState } from 'react'
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";

import { notificationViewed, getNotifications } from '../../config/service/notificationService';





const renderMessage = (notification, index) => {
    switch (notification.type) {
        case 51:
            return (
                <li className="notification-messages" key={index}>
                    <div className="notification-msg-wrapper">
                        <div className="notification-item">
                            {notification.userId ?  <p className="msg"> <b>{`${notification.userId.firstName}  ${notification.userId.lastName}`}</b> has logged in</p> : null}
                        </div>
                        <div className="notification-date-time justify-content-end">
                            {notification.userId ? <Link  to={'/user/' + notification.userId._id} className="view-btn btn btn-primary">View</Link> : null}
                            <small className="notification-time">{dateFormat(notification.created, "ddd, d mmm yyyy h:MM TT")}</small>
                        </div>
                    </div>
                </li>
            )

            break;
        case 52:
            return (
                <li className="notification-messages" key={index}>
                    <div className="notification-msg-wrapper">
                        <div className="notification-item">
                            {notification.userId ?
                            <p className="msg">Received {notification.message} messages from <b>{`${notification.userId.firstName}  ${notification.userId.lastName}`}</b></p> : null }
                        </div>
                        <div className="notification-date-time justify-content-end">
                            {notification.userId ? <Link  to={'/user/' + notification.userId._id + '/message'} className="view-btn btn btn-primary">View</Link> : null }
                            <small className="notification-time">{dateFormat(notification.created, "ddd, d mmm yyyy h:MM TT")}</small>
                        </div>
                    </div>
                </li>
            )

            break;
        case 53:
            return (
                <li className="notification-messages" key={index}>
                    <div className="notification-msg-wrapper">
                        <div className="notification-item">
                            { notification.userId ? <p className="msg"> <b> {`${notification.userId.firstName}  ${notification.userId.lastName}`} </b> logged diet</p> : null }
                        </div>
                        <div className="notification-date-time justify-content-end">
                            { notification.userId ? <Link  to={'/user/' + notification.userId._id + '/dietTrack'} className="view-btn btn btn-primary">View</Link> : null}
                            <small className="notification-time">{dateFormat(notification.created, "ddd, d mmm yyyy h:MM TT")}</small>
                        </div>
                    </div>
                </li>
            )

            break;

        default:
            return ''
    }
}

const markReadNotification = (notificationId) => {
    let param = {
        id: notificationId,
        status: 1  //0= Unread, 1=Read , 2=All
    }
    notificationViewed(param)
        .then(res => {

            if (res.data.statusCode == 0) {
                console.log("notification error====================");
            }
        })
}

const AllNotification = ({ data, totalNotifications, fetchMoreDatas, hasMores }) => {
    // console.log("data noti", data)
    //const notifications = data?.length ? data : [];
    const [notifications, setNotifications] = React.useState([]);
    const [totalNotification, setTotalNotification] = useState(null)
    const [pageNo, setPageNo] = useState(0);
    const [hasMore, setHasMore] = useState(true);


    const fetchData = () => {
        const params = { 
            params: { 
                pageNo: pageNo,
                count: 10,
                notificationType: ""
            } 
    }
        getNotifications(params)
            .then((res) => {
                if (res?.data?.statusCode == 1) {
                    setNotifications([]);
                    setNotifications(res?.data?.responseData?.result);
                    setTotalNotification(res?.data?.responseData?.totalCount)
                    setHasMore(notifications?.length + 1 < res.data?.responseData?.totalCount)
                    //cogoToast.success(res.responseData.message)
                } else if (res?.data?.statusCode == 0) {
                    //cogoToast.error(res.data.error.errorMessage)
                }
            })
    }

    const fetchMoreData = () => {
        let pageNoLocal = pageNo + 1;
        const params = { 
            params: { 
                pageNo: pageNoLocal,
                count: 10,
                notificationType: ""
            } 
    }
        
        getNotifications(params)
            .then((res) => {
                if (res?.data?.statusCode == 1) {
                    setNotifications(currentArray => [...currentArray, ...res?.data?.responseData?.result]);
                    setTotalNotification(res?.data?.responseData?.totalCount)
                    setPageNo(pageNoLocal);
                    setHasMore(notifications?.length + 1 < res.data?.responseData?.totalCount)
                    //cogoToast.success(res.responseData.message)
                } else if (res?.data?.statusCode == 0) {
                    //cogoToast.error(res.data.error.errorMessage)
                }
            })
        
    };
    
    useEffect(() => {
        fetchData();
    }, []);


    return (

        <div className="card_wrapper">
            <ul className="list-unstyled notification-wrapper">
                <InfiniteScroll
                    dataLength={notifications ? notifications.length : 1}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                >
                    {notifications.length ? notifications?.map((notification, index) => renderMessage(notification, index)) : <p className="text-danger text-center">No Data Found!</p>}

                </InfiniteScroll>

            </ul>
        </div>
    )
}

export default AllNotification